var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-menu', {
    attrs: {
      "offset-y": "",
      "left": "",
      "value": _vm.showMenu,
      "nudge-bottom": "14",
      "max-width": "600",
      "close-on-content-click": false,
      "content-class": "user-profile-menu-content",
      "transition": "scale",
      "fullscreen": _vm.$vuetify.breakpoint.xsOnly
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "text": ""
          },
          on: {
            "click": _vm.open
          }
        }, 'v-btn', attrs, false), on), [_vm._v(" " + _vm._s(_vm.$t("components.analytics.AnalyticsDateRangeSelectorDialog.from")) + " " + _vm._s(_vm.dateFrom) + " " + _vm._s(_vm.$t("components.analytics.AnalyticsDateRangeSelectorDialog.to")) + " " + _vm._s(_vm.dateTo) + " "), _c('v-icon', {
          attrs: {
            "right": ""
          }
        }, [_vm._v(" mdi-menu-down ")])], 1)];
      }
    }])
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticClass: "ma-4"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("components.analytics.AnalyticsDateRangeSelectorDialog.title")))])]), _c('v-card-text', [_c('v-row', [_vm.showSuggested ? _c('v-col', {
    attrs: {
      "md": "5",
      "sm": "4",
      "cols": "12"
    }
  }, [_c('v-list', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-list-item-group', {
    attrs: {
      "mandatory": "",
      "color": "primary"
    },
    model: {
      value: _vm.selectedSuggestedDateIndexTemp,
      callback: function callback($$v) {
        _vm.selectedSuggestedDateIndexTemp = $$v;
      },
      expression: "selectedSuggestedDateIndexTemp"
    }
  }, _vm._l(_vm.items, function (item) {
    return _c('v-list-item', {
      key: item.id,
      on: {
        "click": function click($event) {
          return _vm.usedPredefinedDateRange(item);
        }
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref2) {
          var active = _ref2.active;
          return [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(_vm.$t(item.text)))])], 1), active ? _c('v-list-item-action', [_c('v-icon', [_vm._v("mdi-check")])], 1) : _vm._e()];
        }
      }], null, true)
    });
  }), 1)], 1)], 1) : _vm._e(), _c('v-col', {
    attrs: {
      "md": _vm.showSuggested ? 7 : 12,
      "sm": _vm.showSuggested ? 8 : 12,
      "cols": "12"
    }
  }, [_c('v-card-text', [!_vm.hideSelectedItemText ? _c('div', {
    staticClass: "mb-2"
  }, [_vm._v(" " + _vm._s(_vm.$t(_vm.selectedItemText)) + " ")]) : _vm._e(), _c('v-chip', {
    staticClass: "mr-2",
    attrs: {
      "disabled": _vm.disableFrom
    },
    on: {
      "click": _vm.showFutureSuggestions
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v(" mdi-calendar-start ")]), _vm._v(" " + _vm._s(_vm.temporaryDateFrom) + " ")], 1), _c('v-chip', {
    staticClass: "ml-2",
    attrs: {
      "disabled": _vm.disableTo
    },
    on: {
      "click": _vm.showPastSuggestions
    }
  }, [_vm._v(" " + _vm._s(_vm.temporaryDateTo) + " "), _c('v-icon', {
    attrs: {
      "right": ""
    }
  }, [_vm._v(" mdi-calendar-end ")])], 1)], 1), _c('span', {
    on: {
      "click": _vm.setCustomDate
    }
  }, [_c('v-date-picker', {
    attrs: {
      "flat": "",
      "range": "",
      "full-width": "",
      "show-adjacent-months": "",
      "no-title": "",
      "scrollable": "",
      "first-day-of-week": "1",
      "color": "primary",
      "min": _vm.min ? _vm.min : undefined,
      "max": _vm.max ? _vm.max : _vm.showFuture ? undefined : _vm.today
    },
    on: {
      "input": _vm.clickDateRangeSelector
    },
    model: {
      value: _vm.dateRange,
      callback: function callback($$v) {
        _vm.dateRange = $$v;
      },
      expression: "dateRange"
    }
  })], 1)], 1)], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "ma-4",
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.analytics.AnalyticsDateRangeSelectorDialog.cancel")) + " ")]), _c('v-btn', {
    staticClass: "ma-4",
    attrs: {
      "color": "primary",
      "disabled": _vm.disabled,
      "text": ""
    },
    on: {
      "click": _vm.setDate
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.analytics.AnalyticsDateRangeSelectorDialog.submit")) + " ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }