var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticClass: "title pt-4 mb-n7"
  }, [_c('h4', [_vm._v(" Gebuchte Services ")])]), _c('v-card', {
    staticClass: "my-2",
    attrs: {
      "flat": "",
      "max-width": "700"
    }
  }, [_c('v-card-text', [_vm._l(Object.entries(_vm.bookedServices), function (service, i) {
    return _c('v-switch', {
      key: i,
      attrs: {
        "label": _vm.$t("components.partner.PartnerCreateOrUpdateLicense.bookedServices.".concat(service[0], ".title")),
        "hint": _vm.$t("components.partner.PartnerCreateOrUpdateLicense.bookedServices.".concat(service[0], ".hint")),
        "persistent-hint": "",
        "disabled": _vm.disabled,
        "inset": "",
        "dense": ""
      },
      model: {
        value: _vm.bookedServices["".concat(service[0])],
        callback: function callback($$v) {
          _vm.$set(_vm.bookedServices, "".concat(service[0]), $$v);
        },
        expression: "bookedServices[`${service[0]}`]"
      }
    });
  }), _c('v-switch', {
    attrs: {
      "label": "Weitergabe der Daten an dritte (individuelle Datenschutz)",
      "disabled": _vm.disabled
    },
    model: {
      value: _vm.settings.isDatasharingActive,
      callback: function callback($$v) {
        _vm.$set(_vm.settings, "isDatasharingActive", $$v);
      },
      expression: "settings.isDatasharingActive"
    }
  })], 2), _c('v-card-title', {
    staticClass: "title pt-4 mb-n7"
  }, [_c('h4', [_vm._v("Emaileinstellungen")])]), _c('v-card-text', {
    staticClass: "mt-8"
  }, [_c('v-switch', {
    attrs: {
      "label": "Fahrzeugscheinergebnisse in Email anzeigen",
      "disabled": _vm.disabled
    },
    model: {
      value: _vm.emailSettings.registrationShowResults,
      callback: function callback($$v) {
        _vm.$set(_vm.emailSettings, "registrationShowResults", $$v);
      },
      expression: "emailSettings.registrationShowResults"
    }
  }), _c('v-switch', {
    attrs: {
      "label": "Ksr Button in E-Mail anzeigen",
      "disabled": _vm.disabled
    },
    model: {
      value: _vm.emailSettings.registrationShowKsrButton,
      callback: function callback($$v) {
        _vm.$set(_vm.emailSettings, "registrationShowKsrButton", $$v);
      },
      expression: "emailSettings.registrationShowKsrButton"
    }
  })], 1), _c('v-card-title', {
    staticClass: "title pt-4 mb-n7"
  }, [_c('h4', [_vm._v("Domains und DNS Einträge")])]), _c('v-card-text', {
    staticClass: "mt-8"
  }, [_c('v-combobox', {
    attrs: {
      "disabled": _vm.disabled,
      "rules": _vm.domainRules,
      "label": "Domains",
      "hint": "Darf nicht mit https:// oder http starten. Einfach mmmint.schadensmeldung.digital oder restemeier.schadensmeldung.digital",
      "multiple": "",
      "chips": ""
    },
    model: {
      value: _vm.settings.urls,
      callback: function callback($$v) {
        _vm.$set(_vm.settings, "urls", $$v);
      },
      expression: "settings.urls"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }