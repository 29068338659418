var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-layout-portal', [_c('v-container', [_c('paginated-table', {
    attrs: {
      "title": _vm.$t('partner.ActivityLogTable.title'),
      "controlElements": _vm.controlElements,
      "headers": _vm.headers,
      "allItems": _vm.activityLogs,
      "getModule": _vm.getModule,
      "loading": _vm.isLoadingAll || _vm.isLoading
    },
    on: {
      "refresh": _vm.refresh
    },
    scopedSlots: _vm._u([{
      key: "item.timestamp.created",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(_vm._f("simpleDoubleDigitDate")(item.timestamp.created)) + " ")];
      }
    }, {
      key: "item.user.userName",
      fn: function fn(_ref2) {
        var _item$user;

        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$user = item.user) === null || _item$user === void 0 ? void 0 : _item$user.userName) + " ")];
      }
    }])
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }