var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('the-layout-portal-list', {
    attrs: {
      "title": _vm.$t('components.PartnerMessageListPaginated.title'),
      "filterList": _vm.filterList,
      "loading": _vm.loading,
      "isLoadingMore": _vm.isLoadingMore,
      "getModule": _vm.getModule
    },
    on: {
      "loadMore": _vm.loadMore,
      "refresh": _vm.refresh
    },
    scopedSlots: _vm._u([{
      key: "options",
      fn: function fn() {
        return [_vm.canCreateMessage ? _c('template-dialog', {
          ref: "templateDialog",
          attrs: {
            "icon": "mdi-plus",
            "partnerId": _vm.partner._id,
            "to": [],
            "from": [_vm.partner]
          }
        }) : _vm._e()];
      },
      proxy: true
    }, {
      key: "listItem",
      fn: function fn(_ref) {
        var listItem = _ref.listItem;
        return [_c('v-list-item', {
          staticClass: "item",
          attrs: {
            "disabled": _vm.isLoadingDetail
          },
          on: {
            "click": function click($event) {
              return _vm.set(listItem);
            }
          }
        }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(listItem.to) + " ")]), _c('v-list-item-subtitle', {
          staticClass: "text--primary"
        }, [_vm._v(" " + _vm._s(listItem.content.subject) + " ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.itemFunctions.body(listItem)))])], 1), _c('v-list-item-action', [_c('v-list-item-action-text', [_vm._v(_vm._s(_vm.itemFunctions.date(listItem)))])], 1)], 1)];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }