var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.message ? _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', [_vm._v(_vm._s(_vm.message.content.subject) + " "), _c('v-spacer'), _vm.canCreateMessage ? _c('template-dialog', {
    ref: "templateDialog",
    attrs: {
      "icon": "mdi-reply-outline",
      "partnerId": _vm.partner._id,
      "to": _vm.to,
      "from": [_vm.partner]
    }
  }) : _vm._e()], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "9"
    }
  }, [_c('div', {
    staticClass: "pb-3 pt-2 ms-4"
  }, [_c('v-avatar', {
    staticClass: "white--text",
    attrs: {
      "color": "primary"
    }
  }, [_vm._v(" " + _vm._s(_vm.fromMail[0].toUpperCase()) + " ")]), _c('div', {
    staticClass: "d-inline-flex flex-column justify-center ms-3",
    staticStyle: {
      "vertical-align": "middle"
    }
  }, [_c('span', {
    staticClass: "text--primary text-truncate font-weight-semibold mb-n1"
  }, [_vm._v(_vm._s(_vm.fromMail))]), _c('small', {
    staticClass: "text--disabled"
  }, [_vm._v(" " + _vm._s(_vm.$t("components.PartnerMessageDetail.to", {
    mail: _vm.message.to
  })))])]), _c('v-spacer')], 1)]), _c('v-col', {
    attrs: {
      "align": "right",
      "cols": "12",
      "sm": "3"
    }
  }, [_c('v-card-text', [_vm._v(_vm._s(_vm.date))])], 1)], 1), _c('v-card-text', [_c('template-editor', {
    staticClass: "mt-2 mx-n2 pb-2",
    attrs: {
      "value": _vm.message.content.body,
      "readOnly": true,
      "outlined": false
    }
  })], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }