var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('card', {
    key: 'damage' + _vm.key,
    attrs: {
      "margin": 0,
      "flat": "",
      "outlined": "",
      "hideTitle": true,
      "subtitle": _vm.$t('components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.report.title.report')
    }
  }, [_c('div', {
    staticClass: "pt-2 pr-2 pl-2"
  }, [_c('v-form', {
    model: {
      value: _vm.isValid,
      callback: function callback($$v) {
        _vm.isValid = $$v;
      },
      expression: "isValid"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', {
    attrs: {
      "label": _vm.$t('components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.report.message'),
      "flat": "",
      "outlined": ""
    },
    model: {
      value: _vm.reportDto.message,
      callback: function callback($$v) {
        _vm.$set(_vm.reportDto, "message", $$v);
      },
      expression: "reportDto.message"
    }
  })], 1), _vm.showSelector ? _c('v-col', {
    attrs: {
      "cols": "12",
      "align": "center",
      "justify": "center"
    }
  }, [_vm.renderComponent ? _c('damage-locator-combined', {
    ref: "DamageLocator",
    staticStyle: {
      "max-width": "200px"
    },
    attrs: {
      "isEdit": true,
      "reportType": _vm.reportDto.reportType,
      "clickedColor": _vm.color,
      "damage-locations": _vm.damageLocation,
      "data-test-damage-locator-combined": ""
    },
    on: {
      "damageLocation": _vm.forceRerender,
      "svgClicked": _vm.svgClicked
    }
  }) : _vm._e(), _c('v-select', {
    attrs: {
      "label": _vm.$t('components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.report.damage'),
      "outlined": "",
      "multiple": "",
      "items": _vm.damageItems,
      "data-test-damage-locator-select": ""
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.report.damages.".concat(item))) + " ")];
      }
    }, {
      key: "selection",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.report.damages.".concat(item))) + " ")];
      }
    }], null, false, 412354524),
    model: {
      value: _vm.reportDto.damage,
      callback: function callback($$v) {
        _vm.$set(_vm.reportDto, "damage", $$v);
      },
      expression: "reportDto.damage"
    }
  })], 1) : _vm._e(), _c('v-col', [_c('v-select', {
    attrs: {
      "label": _vm.$t('components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.report.reportType'),
      "flat": "",
      "outlined": "",
      "items": _vm.reportTypeItems
    },
    on: {
      "change": _vm.onReportTypeSelected
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.report.reportTypes.".concat(item))) + " ")];
      }
    }, {
      key: "selection",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.report.reportTypes.".concat(item))) + " ")];
      }
    }]),
    model: {
      value: _vm.reportDto.reportType,
      callback: function callback($$v) {
        _vm.$set(_vm.reportDto, "reportType", $$v);
      },
      expression: "reportDto.reportType"
    }
  })], 1)], 1)], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }