var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('timeline-card', {
    attrs: {
      "title": _vm.$t('timeLine.PartnerRegistrationTimeLineElement.title')
    }
  }, [_c('Can', {
    attrs: {
      "I": _vm.ActionEnum.UPDATE,
      "a": _vm.ResourceEnum.REPORT
    }
  }, [_c('template', {
    slot: "menu"
  }, [_c('Can', {
    attrs: {
      "I": _vm.ActionEnum.READ,
      "a": _vm.ResourceEnum.ADMIN
    }
  }, [_vm.canReadRegistrationImage ? _c('v-btn', {
    staticClass: "mr-2",
    attrs: {
      "outlined": "",
      "x-small": "",
      "dense": "",
      "color": "info",
      "loading": _vm.loadRegistrationResult
    },
    on: {
      "click": _vm.readRegistrationImage
    }
  }, [_vm._v(" Read "), _c('v-icon', {
    attrs: {
      "x-small": "",
      "dense": ""
    }
  }, [_vm._v("mdi-creation")])], 1) : _vm._e(), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "width": "300"
    },
    model: {
      value: _vm.loadRegistrationResult,
      callback: function callback($$v) {
        _vm.loadRegistrationResult = $$v;
      },
      expression: "loadRegistrationResult"
    }
  }, [_c('v-card', {
    attrs: {
      "dark": ""
    }
  }, [_c('v-card-subtitle', [_vm._v("Fahrzeugschein wird verarbeitet...")]), _c('v-card-text', [_c('v-row', {
    staticClass: "fill-height ma-0 pt-2",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "color": "primary"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('registration-update-dialog', {
    ref: "dialog",
    attrs: {
      "icon": false,
      "loading": _vm.loading,
      "registration": _vm.registration,
      "registrationImageLink": _vm.registrationImageLink
    },
    on: {
      "update": _vm.update
    }
  })], 1)], 2), _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [!_vm.loading ? _c('v-data-table', {
    attrs: {
      "dense": "",
      "item-key": "key",
      "headers": _vm.headers,
      "items": _vm.notEmptyData,
      "hide-default-header": "",
      "hide-default-footer": "",
      "disable-pagination": ""
    }
  }) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }