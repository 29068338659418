var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-layout-portal-split', {
    scopedSlots: _vm._u([{
      key: "list",
      fn: function fn() {
        return [!_vm.loading ? _c('partner-message-list-paginated', {
          on: {
            "isLoadingDetail": _vm.setIsLoadingDetail
          }
        }) : _vm._e(), _vm.loading ? _c('v-skeleton-loader', {
          staticClass: "mx-auto",
          attrs: {
            "type": "divider, card-heading, divider, list-item-two-line,list-item-two-line, divider"
          }
        }) : _vm._e()];
      },
      proxy: true
    }, {
      key: "content",
      fn: function fn() {
        return [!_vm.isDetailLoading ? _c('partner-message-detail') : _c('v-skeleton-loader', {
          attrs: {
            "type": "article, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line"
          }
        })];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }